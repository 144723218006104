import ModelBase from "@/apps/core/models/modelBase.js";

const modelName = "perawatan";
const modelPath = `/${modelName}/`;

class Perawatan extends ModelBase {
  constructor() {
    let model = {
      id: null,
      pasien: null,
      kode_bill: null,
      tanggal_masuk: null
    };
    let requiredFields = ["pasien", "kode_bill", "tanggal_masuk"];
    super(modelName, model, requiredFields, [], modelPath);
  }

  initObservables() {
    super.initObservables();
    this.observables.pasienInput = "";
  }

  getLoadData(data) {
    let tdata = super.getLoadData(data);
    tdata.pasien = tdata.pasien.id;
    return tdata;
  }
}

export default Perawatan;